import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import Home from './Home';
import Terms from './Terms';
function App() {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<PrivacyPolicy />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<Terms />} />
            </Routes>
        </HashRouter>
    );
}

export default App;
