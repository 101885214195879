import React from 'react';

export default function Terms() {
    return (
        <div style={{ backgroundColor: '#000', padding: 40 }}>
            <div class="group w-full text-gray-800 dark:text-gray-100 border-b border-black/10 dark:border-gray-900/50 bg-gray-50 dark:bg-[#444654]">
        <div class="flex p-4 gap-4 text-base md:gap-6 md:max-w-2xl lg:max-w-xl xl:max-w-3xl md:py-6 lg:px-0 m-auto">
          <div class="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
            <div class="flex flex-grow flex-col gap-3">
              <div class="flex flex-col items-start gap-4 whitespace-pre-wrap break-words">
                <div class="markdown prose w-full break-words dark:prose-invert light">
                  <p>Terms of Service</p>
                  <p>
                    Welcome to Sun Moon Rising, a dating app developed and
                    operated by Sun Moon Rising LLC. These Terms of Service
                    ("Terms") govern your use of the Sun Moon Rising app and any
                    related services provided by Sun Moon Rising LLC (referred
                    to as "we," "us," or "our"). By accessing or using the Sun
                    Moon Rising app, you agree to be bound by these Terms. If
                    you do not agree with any part of these Terms, please do not
                    use our app or services.
                  </p>
                  <ol>
                    <li>
                      <p>
                        Acceptance of Terms By using the Sun Moon Rising app,
                        you affirm that you are at least 18 years old and have
                        the legal capacity to enter into these Terms. If you are
                        accessing or using the app on behalf of a company or
                        other legal entity, you represent that you have the
                        authority to bind such entity to these Terms.
                      </p>
                    </li>
                    <li>
                      <p>
                        Privacy Your privacy is important to us. Our Privacy
                        Policy explains how we collect, use, and disclose
                        information about you when you use our app. By using the
                        Sun Moon Rising app, you agree that we can collect and
                        use your information in accordance with our Privacy
                        Policy.
                      </p>
                    </li>
                    <li>
                      <p>
                        Account Registration To use the Sun Moon Rising app, you
                        must create an account. You agree to provide accurate
                        and complete information during the registration process
                        and to keep your account information up to date. You are
                        responsible for maintaining the confidentiality of your
                        account credentials and for all activities that occur
                        under your account. If you suspect any unauthorized use
                        of your account, you must notify us immediately.
                      </p>
                    </li>
                    <li>
                      <p>
                        App Usage a. Eligibility: You must be at least 18 years
                        old to use the Sun Moon Rising app. Any use or access by
                        individuals under the age of 18 is strictly prohibited.
                        b. Personal Use: The Sun Moon Rising app is intended for
                        personal, non-commercial use only. You agree not to use
                        the app for any illegal or unauthorized purposes. c.
                        Prohibited Content: You agree not to upload, post, or
                        transmit any content that is unlawful, defamatory,
                        abusive, obscene, offensive, or violates any third-party
                        rights. d. Safety and Security: While we strive to
                        provide a safe and secure environment, you acknowledge
                        and understand that we cannot guarantee the
                        authenticity, accuracy, or reliability of other users'
                        profiles, messages, or interactions. It is your
                        responsibility to exercise caution and use common sense
                        when communicating with other users.
                      </p>
                    </li>
                    <li>
                      <p>
                        Intellectual Property The Sun Moon Rising app and its
                        content, including but not limited to text, graphics,
                        logos, images, software, and any other materials
                        provided by us, are protected by intellectual property
                        rights. You agree not to modify, distribute, reproduce,
                        or create derivative works based on the app or its
                        content without our prior written consent.
                      </p>
                    </li>
                    <li>
                      <p>
                        Third-Party Links and Services The Sun Moon Rising app
                        may contain links to third-party websites or services.
                        We do not endorse or assume any responsibility for the
                        content, privacy practices, or actions of these third
                        parties. Your interactions with these third parties are
                        solely between you and them.
                      </p>
                    </li>
                    <li>
                      <p>
                        Limitation of Liability To the maximum extent permitted
                        by applicable law, Sun Moon Rising LLC and its
                        affiliates, officers, directors, employees, and agents
                        will not be liable for any direct, indirect, incidental,
                        consequential, or special damages arising out of or in
                        connection with your use of the Sun Moon Rising app or
                        these Terms.
                      </p>
                    </li>
                    <li>
                      <p>
                        Modifications to the Terms We reserve the right to
                        modify or update these Terms at any time without prior
                        notice. The updated Terms will be effective upon
                        posting. Your continued use of the Sun Moon Rising app
                        after the posting of any changes constitutes your
                        acceptance of such changes.
                      </p>
                    </li>
                    <li>
                      <p>
                        Contact Us If you have any questions or concerns about
                        these Terms, please contact us at{" "}
                        <a style={{color: '#0cf'}}
                          href="mailto:support@sunmoonrising.app"
                          target="_new"
                        >
                          support@sunmoonrising.app
                        </a>
                        .
                      </p>
                    </li>
                  </ol>
                  <p>
                    By using the Sun Moon Rising app, you agree to comply with
                    these Terms of Service. Please read them carefully before
                    using the app.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    );
}
