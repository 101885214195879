import React from "react";

export default function PrivacyPolicy() {
  return (
    <div style={{padding: 40}}>
      <div>
        <p>Privacy Policy</p>
        <p>Effective Date: 05/28/2023</p>
        <p>
          Sun Moon Rising LLC ("we," "us," or "our") is committed to protecting
          the privacy of our users ("you" or "your") and maintaining the
          confidentiality and security of your personal information. This
          Privacy Policy describes how we collect, use, and disclose information
          when you use the Sun Moon Rising app ("app") and the choices you have
          associated with that information. By using our app, you consent to the
          practices described in this Privacy Policy.
        </p>
        <ol>
          <li>
            <p>
              Information We Collect a. Personal Information: When you create an
              account on the app, we may collect personal information such as
              your name, email address, gender, date of birth, profile picture,
              and other information you choose to provide. b. User Content: We
              collect information that you voluntarily provide, such as
              messages, photos, and other content you submit while using the
              app. c. Usage Information: We may collect information about your
              use of the app, including your device information, IP address,
              location data, app usage statistics, and browsing history.
            </p>
          </li>
          <li>
            <p>
              Use of Information a. Provide and Improve the App: We use the
              information we collect to provide, maintain, and improve the app's
              functionality and user experience. b. Personalization: We may use
              your information to personalize your app experience, such as
              providing tailored recommendations and content. c. Communication:
              We may use your email address to send you notifications, updates,
              and promotional materials related to the app. You can opt-out of
              receiving promotional emails by following the instructions
              provided in the email. d. Analytics and Research: We may use
              aggregated and anonymized information for analytical purposes,
              such as analyzing app usage patterns and conducting research to
              improve our services. e. Legal Compliance: We may use or disclose
              your information if required by law, regulation, legal process, or
              governmental request.
            </p>
          </li>
          <li>
            <p>
              Information Sharing a. With Other Users: Certain information, such
              as your username, profile picture, and user-generated content, may
              be visible to other users of the app. b. Service Providers: We may
              share your information with trusted third-party service providers
              who assist us in operating and maintaining the app, such as
              hosting providers, analytics providers, and customer support
              services. c. Business Transfers: In the event of a merger,
              acquisition, or sale of all or a portion of our assets, your
              information may be transferred to the acquiring entity. d. Legal
              Requirements: We may disclose your information if we believe it is
              necessary to comply with applicable laws, regulations, legal
              processes, or enforceable governmental requests.
            </p>
          </li>
          <li>
            <p>
              Data Security We take reasonable measures to protect your
              information from unauthorized access, alteration, disclosure, or
              destruction. However, no data transmission or storage system can
              be guaranteed to be 100% secure. Therefore, while we strive to
              protect your information, we cannot guarantee its absolute
              security.
            </p>
          </li>
          <li>
            <p>
              Children's Privacy The app is not intended for individuals under
              the age of 18. We do not knowingly collect personal information
              from children. If you believe that we have inadvertently collected
              personal information from a child, please contact us immediately,
              and we will take steps to delete the information as soon as
              possible.
            </p>
          </li>
          <li>
            <p>
              Third-Party Links and Services The app may contain links to
              third-party websites or services. We are not responsible for the
              privacy practices or content of these third parties. Your
              interactions with those third parties are governed by their
              respective privacy policies.
            </p>
          </li>
          <li>
            <p>
              Changes to this Privacy Policy We may update this Privacy Policy
              from time to time. We will notify you of any material changes by
              posting the updated Privacy Policy on the app. Your continued use
              of the app following the posting of changes constitutes your
              acceptance of such changes.
            </p>
          </li>
          <li>
            <p>
              Contact Us If you have any questions or concerns regarding this
              Privacy Policy or our privacy practices, please contact us at{" "}
              <a href="mailto:support@sunmoonrising.app" target="_new">
                support@sunmoonrising.app
              </a>
              .
            </p>
          </li>
        </ol>
        <p>
          By using the Sun Moon Rising app, you signify your understanding and
          acceptance of this Privacy Policy.
        </p>
      </div>
    </div>
  );
}
